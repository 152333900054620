<template>
  <div class="container">
    <!--选择酒店 -->
    <el-dialog
      title="引导页"
      :visible.sync="dialogVisible"
      width="45%"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <el-radio-group v-model="hotel">
        <el-radio-button
          v-for="item in chooseList"
          :key="item.id"
          :value="item.id"
          :label="item.id"
          >{{ item.name }}</el-radio-button
        >
      </el-radio-group>

      <span slot="footer" class="dialog-footer">
        <el-button class="anniu" type="primary" @click="choseHotel"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="创建酒店"
      :visible.sync="hotelVisible"
      width="40%"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <div>
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <div class="banbentwoItem">
            <el-form-item label="酒店名称" prop="hotelName">
              <el-input
                ref="hotelName"
                v-model="formInline.hotelName"
                placeholder="请输入酒店名称"
                maxlength="20"
                type="text"
              ></el-input>
            </el-form-item>
          </div>

          <div class="banbentwo">
            <div class="banbentwoItem">
              <el-form-item label="欢迎词" prop="welcome">
                <el-input
                  ref="welcome"
                  maxlength="100"
                  v-model="formInline.welcome"
                  placeholder="请输入欢迎词"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banbentwoItem">
            <el-form-item label="欢迎语推送延时" prop="delay">
              <el-input
                v-model="formInline.delay"
                placeholder="请输入欢迎语推送延时"
                type="text"
              ></el-input>
            </el-form-item>
          </div>
          <div class="banbentwo">
            <div class="banbentwoItem">
              <el-form-item label="备注">
                <el-input
                  ref="remark"
                  maxlength="100"
                  v-model="formInline.remark"
                  placeholder="请输入备注"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banben">
            <div class="bandanItem">
              <el-form-item label="试用期到期" prop="validDate">
                <el-date-picker
                  v-model="formInline.validDate"
                  type="date"
                  placeholder="选择试用期到期日期"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="bandanItem">
              <el-form-item label="排序" prop="dispOrder">
                <el-input
                  ref="dispOrder"
                  maxlength="20"
                  v-model="formInline.dispOrder"
                  placeholder="请输入排序"
                  type="text"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <el-form-item label="音乐地址" prop="musicUrl">
            <el-input
              ref="code"
              v-model="formInline.musicUrl"
              placeholder="请输入音乐地址"
              maxlength="20"
              type="text"
            ></el-input>
          </el-form-item>
          <el-form-item label="酒店公钥" prop="apiKey">
            <el-input
              ref="code"
              v-model="formInline.apiKey"
              placeholder="请输入酒店公钥"
              maxlength="20"
              type="text"
            ></el-input>
          </el-form-item>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { postRequest } from "@/api/api.js";
export default {
  data() {
    return {
      dialogVisible: true,
      hotelVisible: false,
      formInline: {},
      hotelList: [],
      chooseList: [
        { id: 0, name: "选择酒店" },
        { id: 1, name: "创建酒店" },
      ],
      selectHotel: {},
      user: {},
      hotel: null,
      rules: {
        hotelName: [
          {
            required: true,
            message: "请输入酒店名称，不能为空",
            trigger: "blur",
          },
        ],
        welcome: [
          {
            required: true,
            message: "请输入欢迎词，不能为空",
            trigger: "blur",
          },
        ],
        delay: [
          {
            required: true,
            message: "请输入欢迎语推送延时，不能为空",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入备注，不能为空",
            trigger: "blur",
          },
        ],
        dispOrder: [
          {
            required: true,
            message: "请输入排序，不能为空",
            trigger: "blur",
          },
        ],
        // musicUrl: [
        //   {
        //     required: true,
        //     message: "请输入音乐地址，不能为空",
        //     trigger: "blur",
        //   },
        // ],
        validDate: [
          {
            required: true,
            message: "请选择试用期到期日期，不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {},
  created() {
    this.user = JSON.parse(window.localStorage.getItem("user"));
    this.hotelList = JSON.parse(window.localStorage.getItem("hotelList"));
  },
  methods: {
    // 选择酒店
    choseHotel() {
      if (this.hotel == null) {
        this.$message({
          duration: 5000,
          message: "请选择下一步！",
          center: true,
        });
        return;
      }
      if (this.hotel == 0) {
        this.$router.push("/choseHotel");
      }
      if (this.hotel == 1) {
        this.$router.push("/creatHotel");
      }
      return;
      this.hotelList.forEach((item) => {
        if (item.id == this.hotel) {
          this.selectHotel.hotelId = item.id;
          this.selectHotel.hotelName = item.hotelName;
        }
      });
      this.selectHotel.userCode = this.user.userCode;
      this.selectHotel.userName = this.user.userName;
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("/selectHotel", this.selectHotel).then((res) => {
        if (res.status == 200) {
          localStorage.setItem("hotelInfor", JSON.stringify(res.data));
          localStorage.setItem("findPage", 0);
          this.$store.state.roomList = null;
          this.getRoom();
          if (this.$store.state.roomList.length > 0) {
            this.$nextTick(() => {
              loadingInstance.close();
            });
          }
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          }); //如果无法获取这一条数据，就报错
        }
      });
    },
    closeDialog() {
      if (this.hotel == null) {
        this.$message({
          duration: 5000,
          message: "请选择下一步！",
          center: true,
        });
        this.dialogVisible = true;
        return;
      }
    },
  },
};
</script>

<style scoped  >
>>> .el-icon-close {
  display: none;
}
</style>
